/* tslint:disable */

// This code was generated by RockIt.PwaTools.Dto
// Module: api
// Commands: 157
// Command-Results: 157
// Dto-Interfaces: 29
// Enums: 30

import { Command, CommandResult } from "../api/api";

//#region Commands

export class AddContainerCommand implements Command {
    newContainer: Container;
    static create(command: AddContainerCommand): AddContainerCommand {
        command["__CommandType"] = "AddContainerCommand";
        return command;
    }
}
export class AddDutyCommand implements Command {
    newDuty: Duty;
    static create(command: AddDutyCommand): AddDutyCommand {
        command["__CommandType"] = "AddDutyCommand";
        return command;
    }
}
export class AddEventCommand implements Command {
    newEvent: Event;
    static create(command: AddEventCommand): AddEventCommand {
        command["__CommandType"] = "AddEventCommand";
        return command;
    }
}
export class AddImageCommand implements Command {
    newImage: Image;
    static create(command: AddImageCommand): AddImageCommand {
        command["__CommandType"] = "AddImageCommand";
        return command;
    }
}
export class AddImageContentCommand implements Command {
    base64Image: string;
    static create(command: AddImageContentCommand): AddImageContentCommand {
        command["__CommandType"] = "AddImageContentCommand";
        return command;
    }
}
export class AddInfoCommand implements Command {
    newInfo: Info;
    static create(command: AddInfoCommand): AddInfoCommand {
        command["__CommandType"] = "AddInfoCommand";
        return command;
    }
}
export class AddLinkCommand implements Command {
    newLink: Link;
    static create(command: AddLinkCommand): AddLinkCommand {
        command["__CommandType"] = "AddLinkCommand";
        return command;
    }
}
export class AddNoticeCommand implements Command {
    newNotice: Notice;
    static create(command: AddNoticeCommand): AddNoticeCommand {
        command["__CommandType"] = "AddNoticeCommand";
        return command;
    }
}
export class AddPlaceCommand implements Command {
    newPlace: Place;
    static create(command: AddPlaceCommand): AddPlaceCommand {
        command["__CommandType"] = "AddPlaceCommand";
        return command;
    }
}
export class AddRefContainerCommand implements Command {
    newRefContainer: RefContainer;
    static create(command: AddRefContainerCommand): AddRefContainerCommand {
        command["__CommandType"] = "AddRefContainerCommand";
        return command;
    }
}
export class AddRefEventCommand implements Command {
    newEvent: RefEvent;
    static create(command: AddRefEventCommand): AddRefEventCommand {
        command["__CommandType"] = "AddRefEventCommand";
        return command;
    }
}
export class AddRefImageCommand implements Command {
    newImage: RefImage;
    static create(command: AddRefImageCommand): AddRefImageCommand {
        command["__CommandType"] = "AddRefImageCommand";
        return command;
    }
}
export class AddRefImageContentCommand implements Command {
    base64Image: string;
    static create(command: AddRefImageContentCommand): AddRefImageContentCommand {
        command["__CommandType"] = "AddRefImageContentCommand";
        return command;
    }
}
export class AddRefInfoCommand implements Command {
    newInfo: RefInfo;
    static create(command: AddRefInfoCommand): AddRefInfoCommand {
        command["__CommandType"] = "AddRefInfoCommand";
        return command;
    }
}
export class AddRefLinkCommand implements Command {
    newLink: RefLink;
    static create(command: AddRefLinkCommand): AddRefLinkCommand {
        command["__CommandType"] = "AddRefLinkCommand";
        return command;
    }
}
export class AddRefOLICommand implements Command {
    newOli: RefOLI;
    static create(command: AddRefOLICommand): AddRefOLICommand {
        command["__CommandType"] = "AddRefOLICommand";
        return command;
    }
}
export class AddRefPlaceCommand implements Command {
    newRefPlace: RefPlace;
    static create(command: AddRefPlaceCommand): AddRefPlaceCommand {
        command["__CommandType"] = "AddRefPlaceCommand";
        return command;
    }
}
export class AddRefRegionCommand implements Command {
    newRefRegion: RefRegion;
    static create(command: AddRefRegionCommand): AddRefRegionCommand {
        command["__CommandType"] = "AddRefRegionCommand";
        return command;
    }
}
export class AddRefRestoCommand implements Command {
    newResto: RefResto;
    static create(command: AddRefRestoCommand): AddRefRestoCommand {
        command["__CommandType"] = "AddRefRestoCommand";
        return command;
    }
}
export class AddRefStayCommand implements Command {
    newStay: RefStay;
    static create(command: AddRefStayCommand): AddRefStayCommand {
        command["__CommandType"] = "AddRefStayCommand";
        return command;
    }
}
export class AddRefTransferCommand implements Command {
    newRefTransfer: RefTransfer;
    static create(command: AddRefTransferCommand): AddRefTransferCommand {
        command["__CommandType"] = "AddRefTransferCommand";
        return command;
    }
}
export class AddRestoCommand implements Command {
    newResto: Resto;
    static create(command: AddRestoCommand): AddRestoCommand {
        command["__CommandType"] = "AddRestoCommand";
        return command;
    }
}
export class AddStayCommand implements Command {
    newStay: Stay;
    static create(command: AddStayCommand): AddStayCommand {
        command["__CommandType"] = "AddStayCommand";
        return command;
    }
}
export class AddTransferCommand implements Command {
    newTransfer: Transfer;
    static create(command: AddTransferCommand): AddTransferCommand {
        command["__CommandType"] = "AddTransferCommand";
        return command;
    }
}
export class AddTripCommand implements Command {
    newTrip: Trip;
    static create(command: AddTripCommand): AddTripCommand {
        command["__CommandType"] = "AddTripCommand";
        return command;
    }
}
export class AddTripShareCommand implements Command {
    newTripShare: TripShare;
    static create(command: AddTripShareCommand): AddTripShareCommand {
        command["__CommandType"] = "AddTripShareCommand";
        return command;
    }
}
export class AddUsageLogCommand implements Command {
    newUsageLog: UsageLog;
    static create(command: AddUsageLogCommand): AddUsageLogCommand {
        command["__CommandType"] = "AddUsageLogCommand";
        return command;
    }
}
export class AddUsageLogMapsCommand implements Command {
    newUsageLog: UsageLogMaps;
    static create(command: AddUsageLogMapsCommand): AddUsageLogMapsCommand {
        command["__CommandType"] = "AddUsageLogMapsCommand";
        return command;
    }
}
export class AddXDocCommand implements Command {
    newXDoc: XDoc;
    static create(command: AddXDocCommand): AddXDocCommand {
        command["__CommandType"] = "AddXDocCommand";
        return command;
    }
}
export class AddXDocContentCommand implements Command {
    base64Doc: string;
    fileExtension: string;
    static create(command: AddXDocContentCommand): AddXDocContentCommand {
        command["__CommandType"] = "AddXDocContentCommand";
        return command;
    }
}
export class ApplyTripShareKeyCommand implements Command {
    shareKey: string;
    static create(command: ApplyTripShareKeyCommand): ApplyTripShareKeyCommand {
        command["__CommandType"] = "ApplyTripShareKeyCommand";
        return command;
    }
}
export class CopyTripCommand implements Command {
    tripId: number;
    static create(command: CopyTripCommand): CopyTripCommand {
        command["__CommandType"] = "CopyTripCommand";
        return command;
    }
}
export class DeleteContainerCommand implements Command {
    containerId: number;
    static create(command: DeleteContainerCommand): DeleteContainerCommand {
        command["__CommandType"] = "DeleteContainerCommand";
        return command;
    }
}
export class DeleteDutyCommand implements Command {
    dutyId: number;
    static create(command: DeleteDutyCommand): DeleteDutyCommand {
        command["__CommandType"] = "DeleteDutyCommand";
        return command;
    }
}
export class DeleteEventCommand implements Command {
    eventId: number;
    static create(command: DeleteEventCommand): DeleteEventCommand {
        command["__CommandType"] = "DeleteEventCommand";
        return command;
    }
}
export class DeleteImageCommand implements Command {
    imageId: number;
    static create(command: DeleteImageCommand): DeleteImageCommand {
        command["__CommandType"] = "DeleteImageCommand";
        return command;
    }
}
export class DeleteInfoCommand implements Command {
    infoId: number;
    static create(command: DeleteInfoCommand): DeleteInfoCommand {
        command["__CommandType"] = "DeleteInfoCommand";
        return command;
    }
}
export class DeleteLinkCommand implements Command {
    linkId: number;
    static create(command: DeleteLinkCommand): DeleteLinkCommand {
        command["__CommandType"] = "DeleteLinkCommand";
        return command;
    }
}
export class DeleteNoticeCommand implements Command {
    noticeId: number;
    static create(command: DeleteNoticeCommand): DeleteNoticeCommand {
        command["__CommandType"] = "DeleteNoticeCommand";
        return command;
    }
}
export class DeletePlaceCommand implements Command {
    placeId: number;
    static create(command: DeletePlaceCommand): DeletePlaceCommand {
        command["__CommandType"] = "DeletePlaceCommand";
        return command;
    }
}
export class DeleteRefContainerCommand implements Command {
    refContainerId: number;
    static create(command: DeleteRefContainerCommand): DeleteRefContainerCommand {
        command["__CommandType"] = "DeleteRefContainerCommand";
        return command;
    }
}
export class DeleteRefEventCommand implements Command {
    refEventId: number;
    static create(command: DeleteRefEventCommand): DeleteRefEventCommand {
        command["__CommandType"] = "DeleteRefEventCommand";
        return command;
    }
}
export class DeleteRefImageCommand implements Command {
    refImageId: number;
    static create(command: DeleteRefImageCommand): DeleteRefImageCommand {
        command["__CommandType"] = "DeleteRefImageCommand";
        return command;
    }
}
export class DeleteRefInfoCommand implements Command {
    refInfoId: number;
    static create(command: DeleteRefInfoCommand): DeleteRefInfoCommand {
        command["__CommandType"] = "DeleteRefInfoCommand";
        return command;
    }
}
export class DeleteRefLinkCommand implements Command {
    refLinkId: number;
    static create(command: DeleteRefLinkCommand): DeleteRefLinkCommand {
        command["__CommandType"] = "DeleteRefLinkCommand";
        return command;
    }
}
export class DeleteRefOLICommand implements Command {
    refOliId: number;
    static create(command: DeleteRefOLICommand): DeleteRefOLICommand {
        command["__CommandType"] = "DeleteRefOLICommand";
        return command;
    }
}
export class DeleteRefPlaceCommand implements Command {
    refPlaceId: number;
    static create(command: DeleteRefPlaceCommand): DeleteRefPlaceCommand {
        command["__CommandType"] = "DeleteRefPlaceCommand";
        return command;
    }
}
export class DeleteRefRegionCommand implements Command {
    refRegionId: number;
    static create(command: DeleteRefRegionCommand): DeleteRefRegionCommand {
        command["__CommandType"] = "DeleteRefRegionCommand";
        return command;
    }
}
export class DeleteRefRestoCommand implements Command {
    refRestoId: number;
    static create(command: DeleteRefRestoCommand): DeleteRefRestoCommand {
        command["__CommandType"] = "DeleteRefRestoCommand";
        return command;
    }
}
export class DeleteRefStayCommand implements Command {
    refStayId: number;
    static create(command: DeleteRefStayCommand): DeleteRefStayCommand {
        command["__CommandType"] = "DeleteRefStayCommand";
        return command;
    }
}
export class DeleteRefTransferCommand implements Command {
    refTransferId: number;
    static create(command: DeleteRefTransferCommand): DeleteRefTransferCommand {
        command["__CommandType"] = "DeleteRefTransferCommand";
        return command;
    }
}
export class DeleteRestoCommand implements Command {
    restoId: number;
    static create(command: DeleteRestoCommand): DeleteRestoCommand {
        command["__CommandType"] = "DeleteRestoCommand";
        return command;
    }
}
export class DeleteStayCommand implements Command {
    stayId: number;
    static create(command: DeleteStayCommand): DeleteStayCommand {
        command["__CommandType"] = "DeleteStayCommand";
        return command;
    }
}
export class DeleteTransferCommand implements Command {
    transferId: number;
    static create(command: DeleteTransferCommand): DeleteTransferCommand {
        command["__CommandType"] = "DeleteTransferCommand";
        return command;
    }
}
export class DeleteTripCommand implements Command {
    tripId: number;
    static create(command: DeleteTripCommand): DeleteTripCommand {
        command["__CommandType"] = "DeleteTripCommand";
        return command;
    }
}
export class DeleteTripShareCommand implements Command {
    tripShareId: number;
    static create(command: DeleteTripShareCommand): DeleteTripShareCommand {
        command["__CommandType"] = "DeleteTripShareCommand";
        return command;
    }
}
export class DeleteXDocCommand implements Command {
    xDocId: number;
    static create(command: DeleteXDocCommand): DeleteXDocCommand {
        command["__CommandType"] = "DeleteXDocCommand";
        return command;
    }
}
export class DoConvertXCommand implements Command {
    tripId: number;
    static create(command: DoConvertXCommand): DoConvertXCommand {
        command["__CommandType"] = "DoConvertXCommand";
        return command;
    }
}
export class DoImportGeonamesCountriesCommand implements Command {
    static create(command: DoImportGeonamesCountriesCommand): DoImportGeonamesCountriesCommand {
        command["__CommandType"] = "DoImportGeonamesCountriesCommand";
        return command;
    }
}
export class DoImportGeonamesPOICommand implements Command {
    minPopulation: number;
    static create(command: DoImportGeonamesPOICommand): DoImportGeonamesPOICommand {
        command["__CommandType"] = "DoImportGeonamesPOICommand";
        return command;
    }
}
export class DoRepairItemsXCommand implements Command {
    static create(command: DoRepairItemsXCommand): DoRepairItemsXCommand {
        command["__CommandType"] = "DoRepairItemsXCommand";
        return command;
    }
}
export class GetAllRefRegionsCommand implements Command {
    loginCode: string;
    maxClass: EnumRegionClass;
    static create(command: GetAllRefRegionsCommand): GetAllRefRegionsCommand {
        command["__CommandType"] = "GetAllRefRegionsCommand";
        return command;
    }
}
export class GetAllRefRegionsPrepairedCommand implements Command {
    static create(command: GetAllRefRegionsPrepairedCommand): GetAllRefRegionsPrepairedCommand {
        command["__CommandType"] = "GetAllRefRegionsPrepairedCommand";
        return command;
    }
}
export class GetDemoTripCommand implements Command {
    tripId: number;
    static create(command: GetDemoTripCommand): GetDemoTripCommand {
        command["__CommandType"] = "GetDemoTripCommand";
        return command;
    }
}
export class GetDemoTripsCommand implements Command {
    static create(command: GetDemoTripsCommand): GetDemoTripsCommand {
        command["__CommandType"] = "GetDemoTripsCommand";
        return command;
    }
}
export class GetExploreUsageListByFilterCommand implements Command {
    fromDate: Date;
    toDate: Date;
    usageLogFilter: UsageLogMaps;
    static create(command: GetExploreUsageListByFilterCommand): GetExploreUsageListByFilterCommand {
        command["__CommandType"] = "GetExploreUsageListByFilterCommand";
        return command;
    }
}
export class GetLoggedInUserCommand implements Command {
    static create(command: GetLoggedInUserCommand): GetLoggedInUserCommand {
        command["__CommandType"] = "GetLoggedInUserCommand";
        return command;
    }
}
export class GetMapUsageCountItemsCommand implements Command {
    actionType: EnumActionType;
    fromDate: Date;
    notLanguage: string;
    referrer: string;
    toDate: Date;
    userLanguage: string;
    static create(command: GetMapUsageCountItemsCommand): GetMapUsageCountItemsCommand {
        command["__CommandType"] = "GetMapUsageCountItemsCommand";
        return command;
    }
}
export class GetMapUsageLanguageListCommand implements Command {
    fromDate: Date;
    toDate: Date;
    static create(command: GetMapUsageLanguageListCommand): GetMapUsageLanguageListCommand {
        command["__CommandType"] = "GetMapUsageLanguageListCommand";
        return command;
    }
}
export class GetMapUsageListByFilterCommand implements Command {
    fromDate: Date;
    toDate: Date;
    usageLogFilter: UsageLogMaps;
    static create(command: GetMapUsageListByFilterCommand): GetMapUsageListByFilterCommand {
        command["__CommandType"] = "GetMapUsageListByFilterCommand";
        return command;
    }
}
export class GetMapUsagePlannedRoutesCommand implements Command {
    fromDate: Date;
    toDate: Date;
    static create(command: GetMapUsagePlannedRoutesCommand): GetMapUsagePlannedRoutesCommand {
        command["__CommandType"] = "GetMapUsagePlannedRoutesCommand";
        return command;
    }
}
export class GetMapUsageReferrerListCommand implements Command {
    fromDate: Date;
    toDate: Date;
    static create(command: GetMapUsageReferrerListCommand): GetMapUsageReferrerListCommand {
        command["__CommandType"] = "GetMapUsageReferrerListCommand";
        return command;
    }
}
export class GetMapUsageSessionIdListCommand implements Command {
    fromDate: Date;
    toDate: Date;
    static create(command: GetMapUsageSessionIdListCommand): GetMapUsageSessionIdListCommand {
        command["__CommandType"] = "GetMapUsageSessionIdListCommand";
        return command;
    }
}
export class GetMapUsageUserIdListCommand implements Command {
    fromDate: Date;
    toDate: Date;
    static create(command: GetMapUsageUserIdListCommand): GetMapUsageUserIdListCommand {
        command["__CommandType"] = "GetMapUsageUserIdListCommand";
        return command;
    }
}
export class GetRefContainerCommand implements Command {
    refContainerId: number;
    static create(command: GetRefContainerCommand): GetRefContainerCommand {
        command["__CommandType"] = "GetRefContainerCommand";
        return command;
    }
}
export class GetRefContainerImagesCommand implements Command {
    imageSelection: EnumImageSelection;
    refContainerId: number;
    static create(command: GetRefContainerImagesCommand): GetRefContainerImagesCommand {
        command["__CommandType"] = "GetRefContainerImagesCommand";
        return command;
    }
}
export class GetRefEventCommand implements Command {
    refEventId: number;
    static create(command: GetRefEventCommand): GetRefEventCommand {
        command["__CommandType"] = "GetRefEventCommand";
        return command;
    }
}
export class GetRefEventImagesCommand implements Command {
    imageSelection: EnumImageSelection;
    refEventId: number;
    static create(command: GetRefEventImagesCommand): GetRefEventImagesCommand {
        command["__CommandType"] = "GetRefEventImagesCommand";
        return command;
    }
}
export class GetRefImageCommand implements Command {
    refImageId: number;
    static create(command: GetRefImageCommand): GetRefImageCommand {
        command["__CommandType"] = "GetRefImageCommand";
        return command;
    }
}
export class GetRefInfoCommand implements Command {
    refInfoId: number;
    static create(command: GetRefInfoCommand): GetRefInfoCommand {
        command["__CommandType"] = "GetRefInfoCommand";
        return command;
    }
}
export class GetRefLinkCommand implements Command {
    refLinkId: number;
    static create(command: GetRefLinkCommand): GetRefLinkCommand {
        command["__CommandType"] = "GetRefLinkCommand";
        return command;
    }
}
export class GetRefPlaceCommand implements Command {
    includeObjects: boolean;
    refPlaceId: number;
    static create(command: GetRefPlaceCommand): GetRefPlaceCommand {
        command["__CommandType"] = "GetRefPlaceCommand";
        return command;
    }
}
export class GetRefPlaceImagesCommand implements Command {
    imageSelection: EnumImageSelection;
    refPlaceId: number;
    static create(command: GetRefPlaceImagesCommand): GetRefPlaceImagesCommand {
        command["__CommandType"] = "GetRefPlaceImagesCommand";
        return command;
    }
}
export class GetRefRegionCommand implements Command {
    includeObjects: boolean;
    maxCities: number;
    refRegionId: number;
    static create(command: GetRefRegionCommand): GetRefRegionCommand {
        command["__CommandType"] = "GetRefRegionCommand";
        return command;
    }
}
export class GetRefRegionContainersCommand implements Command {
    refRegionId: number;
    static create(command: GetRefRegionContainersCommand): GetRefRegionContainersCommand {
        command["__CommandType"] = "GetRefRegionContainersCommand";
        return command;
    }
}
export class GetRefRegionEventsCommand implements Command {
    refRegionId: number;
    static create(command: GetRefRegionEventsCommand): GetRefRegionEventsCommand {
        command["__CommandType"] = "GetRefRegionEventsCommand";
        return command;
    }
}
export class GetRefRegionImagesCommand implements Command {
    imageSelection: EnumImageSelection;
    refRegionId: number;
    static create(command: GetRefRegionImagesCommand): GetRefRegionImagesCommand {
        command["__CommandType"] = "GetRefRegionImagesCommand";
        return command;
    }
}
export class GetRefRegionInfosCommand implements Command {
    refRegionId: number;
    static create(command: GetRefRegionInfosCommand): GetRefRegionInfosCommand {
        command["__CommandType"] = "GetRefRegionInfosCommand";
        return command;
    }
}
export class GetRefRegionLinksCommand implements Command {
    refRegionId: number;
    static create(command: GetRefRegionLinksCommand): GetRefRegionLinksCommand {
        command["__CommandType"] = "GetRefRegionLinksCommand";
        return command;
    }
}
export class GetRefRegionOlisCommand implements Command {
    linkType: EnumObjectType;
    refRegionId: number;
    static create(command: GetRefRegionOlisCommand): GetRefRegionOlisCommand {
        command["__CommandType"] = "GetRefRegionOlisCommand";
        return command;
    }
}
export class GetRefRegionPlacesCommand implements Command {
    refRegionId: number;
    static create(command: GetRefRegionPlacesCommand): GetRefRegionPlacesCommand {
        command["__CommandType"] = "GetRefRegionPlacesCommand";
        return command;
    }
}
export class GetRefRestoCommand implements Command {
    refRestoId: number;
    static create(command: GetRefRestoCommand): GetRefRestoCommand {
        command["__CommandType"] = "GetRefRestoCommand";
        return command;
    }
}
export class GetRefStayCommand implements Command {
    refStayId: number;
    static create(command: GetRefStayCommand): GetRefStayCommand {
        command["__CommandType"] = "GetRefStayCommand";
        return command;
    }
}
export class GetRefSubRegionsCommand implements Command {
    refRegionId: number;
    static create(command: GetRefSubRegionsCommand): GetRefSubRegionsCommand {
        command["__CommandType"] = "GetRefSubRegionsCommand";
        return command;
    }
}
export class GetRefTransferCommand implements Command {
    refTransferId: number;
    static create(command: GetRefTransferCommand): GetRefTransferCommand {
        command["__CommandType"] = "GetRefTransferCommand";
        return command;
    }
}
export class GetRefTransfersCommand implements Command {
    fromRefPlaceId: number;
    toRefPlaceId: number;
    static create(command: GetRefTransfersCommand): GetRefTransfersCommand {
        command["__CommandType"] = "GetRefTransfersCommand";
        return command;
    }
}
export class GetRefTransfersForRegionCommand implements Command {
    refRegionId: number;
    static create(command: GetRefTransfersForRegionCommand): GetRefTransfersForRegionCommand {
        command["__CommandType"] = "GetRefTransfersForRegionCommand";
        return command;
    }
}
export class GetSharedTripCommand implements Command {
    tripId: number;
    static create(command: GetSharedTripCommand): GetSharedTripCommand {
        command["__CommandType"] = "GetSharedTripCommand";
        return command;
    }
}
export class GetTripCommand implements Command {
    tripId: number;
    static create(command: GetTripCommand): GetTripCommand {
        command["__CommandType"] = "GetTripCommand";
        return command;
    }
}
export class GetTripsCommand implements Command {
    static create(command: GetTripsCommand): GetTripsCommand {
        command["__CommandType"] = "GetTripsCommand";
        return command;
    }
}
export class GetTripSharesForTripCommand implements Command {
    tripId: number;
    static create(command: GetTripSharesForTripCommand): GetTripSharesForTripCommand {
        command["__CommandType"] = "GetTripSharesForTripCommand";
        return command;
    }
}
export class GetUsageNrOfTotalCommand implements Command {
    actionType: EnumActionType;
    appType: EnumAppType;
    fromDate: Date;
    toDate: Date;
    static create(command: GetUsageNrOfTotalCommand): GetUsageNrOfTotalCommand {
        command["__CommandType"] = "GetUsageNrOfTotalCommand";
        return command;
    }
}
export class GetUsageNrOfUserIPsCommand implements Command {
    actionType: EnumActionType;
    appType: EnumAppType;
    fromDate: Date;
    toDate: Date;
    static create(command: GetUsageNrOfUserIPsCommand): GetUsageNrOfUserIPsCommand {
        command["__CommandType"] = "GetUsageNrOfUserIPsCommand";
        return command;
    }
}
export class GetUsageNrOfUsersCommand implements Command {
    actionType: EnumActionType;
    appType: EnumAppType;
    fromDate: Date;
    toDate: Date;
    static create(command: GetUsageNrOfUsersCommand): GetUsageNrOfUsersCommand {
        command["__CommandType"] = "GetUsageNrOfUsersCommand";
        return command;
    }
}
export class GetUsageOfUserCommand implements Command {
    actionType: EnumActionType;
    appType: EnumAppType;
    fromDate: Date;
    toDate: Date;
    userId: number;
    static create(command: GetUsageOfUserCommand): GetUsageOfUserCommand {
        command["__CommandType"] = "GetUsageOfUserCommand";
        return command;
    }
}
export class GetUsersCommand implements Command {
    static create(command: GetUsersCommand): GetUsersCommand {
        command["__CommandType"] = "GetUsersCommand";
        return command;
    }
}
export class RemoveImageContentCommand implements Command {
    blobString: string;
    static create(command: RemoveImageContentCommand): RemoveImageContentCommand {
        command["__CommandType"] = "RemoveImageContentCommand";
        return command;
    }
}
export class RemoveRefImageContentCommand implements Command {
    blobString: string;
    static create(command: RemoveRefImageContentCommand): RemoveRefImageContentCommand {
        command["__CommandType"] = "RemoveRefImageContentCommand";
        return command;
    }
}
export class RemoveUserCommand implements Command {
    userId: number;
    static create(command: RemoveUserCommand): RemoveUserCommand {
        command["__CommandType"] = "RemoveUserCommand";
        return command;
    }
}
export class RemoveXDocContentCommand implements Command {
    blobString: string;
    static create(command: RemoveXDocContentCommand): RemoveXDocContentCommand {
        command["__CommandType"] = "RemoveXDocContentCommand";
        return command;
    }
}
export class ReplaceRefInfoTextCommand implements Command {
    fromString: string;
    toString: string;
    static create(command: ReplaceRefInfoTextCommand): ReplaceRefInfoTextCommand {
        command["__CommandType"] = "ReplaceRefInfoTextCommand";
        return command;
    }
}
export class ReplaceRefLinkUrlCommand implements Command {
    fromString: string;
    toString: string;
    static create(command: ReplaceRefLinkUrlCommand): ReplaceRefLinkUrlCommand {
        command["__CommandType"] = "ReplaceRefLinkUrlCommand";
        return command;
    }
}
export class SaveAsRefEventCommand implements Command {
    refContainerId: number;
    refPlaceId: number;
    refRegionId: number;
    refTransferId: number;
    saveAsRefEvent: Event;
    static create(command: SaveAsRefEventCommand): SaveAsRefEventCommand {
        command["__CommandType"] = "SaveAsRefEventCommand";
        return command;
    }
}
export class SaveAsRefInfoCommand implements Command {
    refContainerId: number;
    refEventId: number;
    refPlaceId: number;
    refRegionId: number;
    refRestoId: number;
    refStayId: number;
    saveAsRefInfo: Info;
    static create(command: SaveAsRefInfoCommand): SaveAsRefInfoCommand {
        command["__CommandType"] = "SaveAsRefInfoCommand";
        return command;
    }
}
export class SaveAsRefLinkCommand implements Command {
    refContainerId: number;
    refEventId: number;
    refPlaceId: number;
    refRegionId: number;
    refRestoId: number;
    refStayId: number;
    saveAsRefLink: Link;
    static create(command: SaveAsRefLinkCommand): SaveAsRefLinkCommand {
        command["__CommandType"] = "SaveAsRefLinkCommand";
        return command;
    }
}
export class SaveAsRefPlaceCommand implements Command {
    refContainerId: number;
    refPlaceId: number;
    refRegionId: number;
    saveAsRefPlace: Place;
    static create(command: SaveAsRefPlaceCommand): SaveAsRefPlaceCommand {
        command["__CommandType"] = "SaveAsRefPlaceCommand";
        return command;
    }
}
export class SaveAsRefRestoCommand implements Command {
    refPlaceId: number;
    saveAsRefResto: Resto;
    static create(command: SaveAsRefRestoCommand): SaveAsRefRestoCommand {
        command["__CommandType"] = "SaveAsRefRestoCommand";
        return command;
    }
}
export class SaveAsRefStayCommand implements Command {
    refPlaceId: number;
    saveAsRefStay: Stay;
    static create(command: SaveAsRefStayCommand): SaveAsRefStayCommand {
        command["__CommandType"] = "SaveAsRefStayCommand";
        return command;
    }
}
export class SaveAsRefTransferCommand implements Command {
    saveAsRefTransfer: Transfer;
    static create(command: SaveAsRefTransferCommand): SaveAsRefTransferCommand {
        command["__CommandType"] = "SaveAsRefTransferCommand";
        return command;
    }
}
export class SearchRefEventByNameCommand implements Command {
    refEventName: string;
    static create(command: SearchRefEventByNameCommand): SearchRefEventByNameCommand {
        command["__CommandType"] = "SearchRefEventByNameCommand";
        return command;
    }
}
export class SearchRefPlaceByNameCommand implements Command {
    refPlaceName: string;
    static create(command: SearchRefPlaceByNameCommand): SearchRefPlaceByNameCommand {
        command["__CommandType"] = "SearchRefPlaceByNameCommand";
        return command;
    }
}
export class SearchRefPlaceListByNameCommand implements Command {
    refRegionId: number;
    searchString: string;
    static create(command: SearchRefPlaceListByNameCommand): SearchRefPlaceListByNameCommand {
        command["__CommandType"] = "SearchRefPlaceListByNameCommand";
        return command;
    }
}
export class SearchRefRegionByNameCommand implements Command {
    refRegionName: string;
    static create(command: SearchRefRegionByNameCommand): SearchRefRegionByNameCommand {
        command["__CommandType"] = "SearchRefRegionByNameCommand";
        return command;
    }
}
export class SearchRefRegionListByNameCommand implements Command {
    searchString: string;
    static create(command: SearchRefRegionListByNameCommand): SearchRefRegionListByNameCommand {
        command["__CommandType"] = "SearchRefRegionListByNameCommand";
        return command;
    }
}
export class SearchRefRestoByNameCommand implements Command {
    refRestoName: string;
    static create(command: SearchRefRestoByNameCommand): SearchRefRestoByNameCommand {
        command["__CommandType"] = "SearchRefRestoByNameCommand";
        return command;
    }
}
export class SearchRefStayByNameCommand implements Command {
    refStayName: string;
    static create(command: SearchRefStayByNameCommand): SearchRefStayByNameCommand {
        command["__CommandType"] = "SearchRefStayByNameCommand";
        return command;
    }
}
export class SyncTripCommand implements Command {
    syncTrip: Trip;
    static create(command: SyncTripCommand): SyncTripCommand {
        command["__CommandType"] = "SyncTripCommand";
        return command;
    }
}
export class TestCommand implements Command {
    dateParameter: Date;
    parameter1: string;
    static create(command: TestCommand): TestCommand {
        command["__CommandType"] = "TestCommand";
        return command;
    }
}
export class TestIfUserCanBeDeletedCommand implements Command {
    userId: number;
    static create(command: TestIfUserCanBeDeletedCommand): TestIfUserCanBeDeletedCommand {
        command["__CommandType"] = "TestIfUserCanBeDeletedCommand";
        return command;
    }
}
export class TestRefOliObjectIdCommand implements Command {
    test: number;
    static create(command: TestRefOliObjectIdCommand): TestRefOliObjectIdCommand {
        command["__CommandType"] = "TestRefOliObjectIdCommand";
        return command;
    }
}
export class UpdateContainerCommand implements Command {
    updateContainer: Container;
    static create(command: UpdateContainerCommand): UpdateContainerCommand {
        command["__CommandType"] = "UpdateContainerCommand";
        return command;
    }
}
export class UpdateDutyCommand implements Command {
    updateDuty: Duty;
    static create(command: UpdateDutyCommand): UpdateDutyCommand {
        command["__CommandType"] = "UpdateDutyCommand";
        return command;
    }
}
export class UpdateEventCommand implements Command {
    updateEvent: Event;
    static create(command: UpdateEventCommand): UpdateEventCommand {
        command["__CommandType"] = "UpdateEventCommand";
        return command;
    }
}
export class UpdateImageCommand implements Command {
    updateImage: Image;
    static create(command: UpdateImageCommand): UpdateImageCommand {
        command["__CommandType"] = "UpdateImageCommand";
        return command;
    }
}
export class UpdateInfoCommand implements Command {
    updateInfo: Info;
    static create(command: UpdateInfoCommand): UpdateInfoCommand {
        command["__CommandType"] = "UpdateInfoCommand";
        return command;
    }
}
export class UpdateLinkCommand implements Command {
    updateLink: Link;
    static create(command: UpdateLinkCommand): UpdateLinkCommand {
        command["__CommandType"] = "UpdateLinkCommand";
        return command;
    }
}
export class UpdateNoticeCommand implements Command {
    updateNotice: Notice;
    static create(command: UpdateNoticeCommand): UpdateNoticeCommand {
        command["__CommandType"] = "UpdateNoticeCommand";
        return command;
    }
}
export class UpdatePlaceCommand implements Command {
    updatePlace: Place;
    static create(command: UpdatePlaceCommand): UpdatePlaceCommand {
        command["__CommandType"] = "UpdatePlaceCommand";
        return command;
    }
}
export class UpdateRefContainerCommand implements Command {
    updateRefContainer: RefContainer;
    static create(command: UpdateRefContainerCommand): UpdateRefContainerCommand {
        command["__CommandType"] = "UpdateRefContainerCommand";
        return command;
    }
}
export class UpdateRefEventCommand implements Command {
    updateEvent: RefEvent;
    static create(command: UpdateRefEventCommand): UpdateRefEventCommand {
        command["__CommandType"] = "UpdateRefEventCommand";
        return command;
    }
}
export class UpdateRefImageCommand implements Command {
    updateImage: RefImage;
    static create(command: UpdateRefImageCommand): UpdateRefImageCommand {
        command["__CommandType"] = "UpdateRefImageCommand";
        return command;
    }
}
export class UpdateRefInfoCommand implements Command {
    updateInfo: RefInfo;
    static create(command: UpdateRefInfoCommand): UpdateRefInfoCommand {
        command["__CommandType"] = "UpdateRefInfoCommand";
        return command;
    }
}
export class UpdateRefLinkCommand implements Command {
    updateLink: RefLink;
    static create(command: UpdateRefLinkCommand): UpdateRefLinkCommand {
        command["__CommandType"] = "UpdateRefLinkCommand";
        return command;
    }
}
export class UpdateRefOLICommand implements Command {
    updateOli: RefOLI;
    static create(command: UpdateRefOLICommand): UpdateRefOLICommand {
        command["__CommandType"] = "UpdateRefOLICommand";
        return command;
    }
}
export class UpdateRefPlaceCommand implements Command {
    updateRefPlace: RefPlace;
    static create(command: UpdateRefPlaceCommand): UpdateRefPlaceCommand {
        command["__CommandType"] = "UpdateRefPlaceCommand";
        return command;
    }
}
export class UpdateRefRegionCommand implements Command {
    updateRefRegion: RefRegion;
    static create(command: UpdateRefRegionCommand): UpdateRefRegionCommand {
        command["__CommandType"] = "UpdateRefRegionCommand";
        return command;
    }
}
export class UpdateRefRestoCommand implements Command {
    updateResto: RefResto;
    static create(command: UpdateRefRestoCommand): UpdateRefRestoCommand {
        command["__CommandType"] = "UpdateRefRestoCommand";
        return command;
    }
}
export class UpdateRefStayCommand implements Command {
    updateStay: RefStay;
    static create(command: UpdateRefStayCommand): UpdateRefStayCommand {
        command["__CommandType"] = "UpdateRefStayCommand";
        return command;
    }
}
export class UpdateRefTransferCommand implements Command {
    updateRefTransfer: RefTransfer;
    static create(command: UpdateRefTransferCommand): UpdateRefTransferCommand {
        command["__CommandType"] = "UpdateRefTransferCommand";
        return command;
    }
}
export class UpdateRestoCommand implements Command {
    updateResto: Resto;
    static create(command: UpdateRestoCommand): UpdateRestoCommand {
        command["__CommandType"] = "UpdateRestoCommand";
        return command;
    }
}
export class UpdateStayCommand implements Command {
    updateStay: Stay;
    static create(command: UpdateStayCommand): UpdateStayCommand {
        command["__CommandType"] = "UpdateStayCommand";
        return command;
    }
}
export class UpdateTransferCommand implements Command {
    updateTransfer: Transfer;
    static create(command: UpdateTransferCommand): UpdateTransferCommand {
        command["__CommandType"] = "UpdateTransferCommand";
        return command;
    }
}
export class UpdateTripCommand implements Command {
    updateTrip: Trip;
    static create(command: UpdateTripCommand): UpdateTripCommand {
        command["__CommandType"] = "UpdateTripCommand";
        return command;
    }
}
export class UpdateTripCompleteCommand implements Command {
    updateTrip: Trip;
    static create(command: UpdateTripCompleteCommand): UpdateTripCompleteCommand {
        command["__CommandType"] = "UpdateTripCompleteCommand";
        return command;
    }
}
export class UpdateTripShareCommand implements Command {
    updateTripShare: TripShare;
    static create(command: UpdateTripShareCommand): UpdateTripShareCommand {
        command["__CommandType"] = "UpdateTripShareCommand";
        return command;
    }
}
export class UpdateUserCommand implements Command {
    updateUser: User;
    static create(command: UpdateUserCommand): UpdateUserCommand {
        command["__CommandType"] = "UpdateUserCommand";
        return command;
    }
}
export class UpdateXDocCommand implements Command {
    updateXDoc: XDoc;
    static create(command: UpdateXDocCommand): UpdateXDocCommand {
        command["__CommandType"] = "UpdateXDocCommand";
        return command;
    }
}

//#endregion


//#region Command results

export interface AddContainerCommandResult extends CommandResult {
    addedContainer: Container;
    statusCode: EnumGlobalStatusCode;
}

export interface AddDutyCommandResult extends CommandResult {
    addedDuty: Duty;
    statusCode: EnumGlobalStatusCode;
}

export interface AddEventCommandResult extends CommandResult {
    addedEvent: Event;
    statusCode: EnumGlobalStatusCode;
}

export interface AddImageCommandResult extends CommandResult {
    addedImage: Image;
    statusCode: EnumGlobalStatusCode;
}

export interface AddImageContentCommandResult extends CommandResult {
    blobString: string;
}

export interface AddInfoCommandResult extends CommandResult {
    addedInfo: Info;
    statusCode: EnumGlobalStatusCode;
}

export interface AddLinkCommandResult extends CommandResult {
    addedLink: Link;
    statusCode: EnumGlobalStatusCode;
}

export interface AddNoticeCommandResult extends CommandResult {
    addedNotice: Notice;
    statusCode: EnumGlobalStatusCode;
}

export interface AddPlaceCommandResult extends CommandResult {
    addedPlace: Place;
    statusCode: EnumGlobalStatusCode;
}

export interface AddRefContainerCommandResult extends CommandResult {
    addedRefContainer: RefContainer;
}

export interface AddRefEventCommandResult extends CommandResult {
    addedRefEvent: RefEvent;
}

export interface AddRefImageCommandResult extends CommandResult {
    addedImage: RefImage;
}

export interface AddRefImageContentCommandResult extends CommandResult {
    blobString: string;
}

export interface AddRefInfoCommandResult extends CommandResult {
    addedInfo: RefInfo;
}

export interface AddRefLinkCommandResult extends CommandResult {
    addedLink: RefLink;
}

export interface AddRefOLICommandResult extends CommandResult {
    addedOli: RefOLI;
}

export interface AddRefPlaceCommandResult extends CommandResult {
    addedRefPlace: RefPlace;
}

export interface AddRefRegionCommandResult extends CommandResult {
    addedRefRegion: RefRegion;
}

export interface AddRefRestoCommandResult extends CommandResult {
    addedResto: RefResto;
}

export interface AddRefStayCommandResult extends CommandResult {
    addedStay: RefStay;
}

export interface AddRefTransferCommandResult extends CommandResult {
    addedRefTransfer: RefTransfer;
}

export interface AddRestoCommandResult extends CommandResult {
    addedResto: Resto;
    statusCode: EnumGlobalStatusCode;
}

export interface AddStayCommandResult extends CommandResult {
    addedStay: Stay;
    statusCode: EnumGlobalStatusCode;
}

export interface AddTransferCommandResult extends CommandResult {
    addedTransfer: Transfer;
    statusCode: EnumGlobalStatusCode;
}

export interface AddTripCommandResult extends CommandResult {
    addedTrip: Trip;
    statusCode: EnumGlobalStatusCode;
}

export interface AddTripShareCommandResult extends CommandResult {
    addedTripShare: TripShare;
    statusCode: EnumGlobalStatusCode;
}

export interface AddUsageLogCommandResult extends CommandResult {
    addedUsageLog: UsageLog;
}

export interface AddUsageLogMapsCommandResult extends CommandResult {
    addedUsageLog: UsageLogMaps;
}

export interface AddXDocCommandResult extends CommandResult {
    addedXDoc: XDoc;
    statusCode: EnumGlobalStatusCode;
}

export interface AddXDocContentCommandResult extends CommandResult {
    blobString: string;
}

export interface ApplyTripShareKeyCommandResult extends CommandResult {
    sharedTrip: Trip;
    statusCode: EnumGlobalStatusCode;
}

export interface CopyTripCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
    trip: Trip;
}

export interface DeleteContainerCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
}

export interface DeleteDutyCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
}

export interface DeleteEventCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
}

export interface DeleteImageCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
}

export interface DeleteInfoCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
}

export interface DeleteLinkCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
}

export interface DeleteNoticeCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
}

export interface DeletePlaceCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
}

export interface DeleteRefContainerCommandResult extends CommandResult {
    success: boolean;
}

export interface DeleteRefEventCommandResult extends CommandResult {
    success: boolean;
}

export interface DeleteRefImageCommandResult extends CommandResult {
    success: boolean;
}

export interface DeleteRefInfoCommandResult extends CommandResult {
    success: boolean;
}

export interface DeleteRefLinkCommandResult extends CommandResult {
    success: boolean;
}

export interface DeleteRefOLICommandResult extends CommandResult {
    success: boolean;
}

export interface DeleteRefPlaceCommandResult extends CommandResult {
    success: boolean;
}

export interface DeleteRefRegionCommandResult extends CommandResult {
    success: boolean;
}

export interface DeleteRefRestoCommandResult extends CommandResult {
    success: boolean;
}

export interface DeleteRefStayCommandResult extends CommandResult {
    success: boolean;
}

export interface DeleteRefTransferCommandResult extends CommandResult {
    success: boolean;
}

export interface DeleteRestoCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
}

export interface DeleteStayCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
}

export interface DeleteTransferCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
}

export interface DeleteTripCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
}

export interface DeleteTripShareCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
}

export interface DeleteXDocCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
}

export interface DoConvertXCommandResult extends CommandResult {
    success: boolean;
}

export interface DoImportGeonamesCountriesCommandResult extends CommandResult {
    success: boolean;
}

export interface DoImportGeonamesPOICommandResult extends CommandResult {
    success: boolean;
}

export interface DoRepairItemsXCommandResult extends CommandResult {
    success: boolean;
}

export interface GetAllRefRegionsCommandResult extends CommandResult {
    refRegions: Array<RefRegion>;
}

export interface GetAllRefRegionsPrepairedCommandResult extends CommandResult {
    refRegions: Array<RefRegion>;
}

export interface GetDemoTripCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
    trip: Trip;
}

export interface GetDemoTripsCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
    trips: Array<Trip>;
}

export interface GetExploreUsageListByFilterCommandResult extends CommandResult {
    returnCode: EnumGlobalStatusCode;
    usageList: Array<UsageLog>;
}

export interface GetLoggedInUserCommandResult extends CommandResult {
    user: User;
}

export interface GetMapUsageCountItemsCommandResult extends CommandResult {
    countItems: number;
}

export interface GetMapUsageLanguageListCommandResult extends CommandResult {
    languageList: Array<string>;
    returnCode: EnumGlobalStatusCode;
}

export interface GetMapUsageListByFilterCommandResult extends CommandResult {
    returnCode: EnumGlobalStatusCode;
    usageList: Array<UsageLogMaps>;
}

export interface GetMapUsagePlannedRoutesCommandResult extends CommandResult {
    returnCode: EnumGlobalStatusCode;
    usageList: Array<UsageLogMaps>;
}

export interface GetMapUsageReferrerListCommandResult extends CommandResult {
    referrerList: Array<string>;
    returnCode: EnumGlobalStatusCode;
}

export interface GetMapUsageSessionIdListCommandResult extends CommandResult {
    returnCode: EnumGlobalStatusCode;
    sessionIdList: Array<string>;
}

export interface GetMapUsageUserIdListCommandResult extends CommandResult {
    returnCode: EnumGlobalStatusCode;
    userIdList: Array<number>;
}

export interface GetRefContainerCommandResult extends CommandResult {
    refContainer: RefContainer;
}

export interface GetRefContainerImagesCommandResult extends CommandResult {
    refImages: Array<RefImage>;
}

export interface GetRefEventCommandResult extends CommandResult {
    refEvent: RefEvent;
}

export interface GetRefEventImagesCommandResult extends CommandResult {
    refImages: Array<RefImage>;
}

export interface GetRefImageCommandResult extends CommandResult {
    refImage: RefImage;
}

export interface GetRefInfoCommandResult extends CommandResult {
    refInfo: RefInfo;
}

export interface GetRefLinkCommandResult extends CommandResult {
    refLink: RefLink;
}

export interface GetRefPlaceCommandResult extends CommandResult {
    refPlace: RefPlace;
}

export interface GetRefPlaceImagesCommandResult extends CommandResult {
    refImages: Array<RefImage>;
}

export interface GetRefRegionCommandResult extends CommandResult {
    refRegion: RefRegion;
}

export interface GetRefRegionContainersCommandResult extends CommandResult {
    refContainers: Array<RefContainer>;
}

export interface GetRefRegionEventsCommandResult extends CommandResult {
    refEvents: Array<RefEvent>;
}

export interface GetRefRegionImagesCommandResult extends CommandResult {
    refImages: Array<RefImage>;
}

export interface GetRefRegionInfosCommandResult extends CommandResult {
    refInfos: Array<RefInfo>;
}

export interface GetRefRegionLinksCommandResult extends CommandResult {
    refLinks: Array<RefLink>;
}

export interface GetRefRegionOlisCommandResult extends CommandResult {
    refOlis: Array<RefOLI>;
}

export interface GetRefRegionPlacesCommandResult extends CommandResult {
    refPlaces: Array<RefPlace>;
}

export interface GetRefRestoCommandResult extends CommandResult {
    refResto: RefResto;
}

export interface GetRefStayCommandResult extends CommandResult {
    refStay: RefStay;
}

export interface GetRefSubRegionsCommandResult extends CommandResult {
    refSubRegions: Array<RefRegion>;
}

export interface GetRefTransferCommandResult extends CommandResult {
    refTransfer: RefTransfer;
}

export interface GetRefTransfersCommandResult extends CommandResult {
    refTransfers: Array<RefTransfer>;
}

export interface GetRefTransfersForRegionCommandResult extends CommandResult {
    refTransfers: Array<RefTransfer>;
}

export interface GetSharedTripCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
    trip: Trip;
}

export interface GetTripCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
    trip: Trip;
}

export interface GetTripsCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
    trips: Array<Trip>;
}

export interface GetTripSharesForTripCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
    tripShares: Array<TripShare>;
}

export interface GetUsageNrOfTotalCommandResult extends CommandResult {
    nrOfTotal: number;
}

export interface GetUsageNrOfUserIPsCommandResult extends CommandResult {
    nrOfUserIPs: number;
}

export interface GetUsageNrOfUsersCommandResult extends CommandResult {
    nrOfUsers: number;
}

export interface GetUsageOfUserCommandResult extends CommandResult {
    nrOfUses: number;
}

export interface GetUsersCommandResult extends CommandResult {
    users: Array<User>;
}

export interface RemoveImageContentCommandResult extends CommandResult {
    success: boolean;
}

export interface RemoveRefImageContentCommandResult extends CommandResult {
    success: boolean;
}

export interface RemoveUserCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
}

export interface RemoveXDocContentCommandResult extends CommandResult {
    success: boolean;
}

export interface ReplaceRefInfoTextCommandResult extends CommandResult {
    countChangedItems: number;
    countFoundItems: number;
    statusCode: EnumGlobalStatusCode;
}

export interface ReplaceRefLinkUrlCommandResult extends CommandResult {
    countChangedItems: number;
    countFoundItems: number;
    statusCode: EnumGlobalStatusCode;
}

export interface SaveAsRefEventCommandResult extends CommandResult {
    errorMessage: string;
    refEventId: number;
    statusCode: EnumGlobalStatusCode;
}

export interface SaveAsRefInfoCommandResult extends CommandResult {
    errorMessage: string;
    refInfoId: number;
    statusCode: EnumGlobalStatusCode;
}

export interface SaveAsRefLinkCommandResult extends CommandResult {
    errorMessage: string;
    refLinkId: number;
    statusCode: EnumGlobalStatusCode;
}

export interface SaveAsRefPlaceCommandResult extends CommandResult {
    errorMessage: string;
    refPlaceId: number;
    statusCode: EnumGlobalStatusCode;
}

export interface SaveAsRefRestoCommandResult extends CommandResult {
    errorMessage: string;
    refRestoId: number;
    statusCode: EnumGlobalStatusCode;
}

export interface SaveAsRefStayCommandResult extends CommandResult {
    errorMessage: string;
    refStayId: number;
    statusCode: EnumGlobalStatusCode;
}

export interface SaveAsRefTransferCommandResult extends CommandResult {
    errorMessage: string;
    statusCode: EnumGlobalStatusCode;
}

export interface SearchRefEventByNameCommandResult extends CommandResult {
    refEvent: RefEvent;
}

export interface SearchRefPlaceByNameCommandResult extends CommandResult {
    refPlace: RefPlace;
}

export interface SearchRefPlaceListByNameCommandResult extends CommandResult {
    refPlaces: Array<RefPlace>;
}

export interface SearchRefRegionByNameCommandResult extends CommandResult {
    refRegion: RefRegion;
}

export interface SearchRefRegionListByNameCommandResult extends CommandResult {
    refRegions: Array<RefRegion>;
}

export interface SearchRefRestoByNameCommandResult extends CommandResult {
    refResto: RefResto;
}

export interface SearchRefStayByNameCommandResult extends CommandResult {
    refStay: RefStay;
}

export interface SyncTripCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
    syncedTrip: Trip;
}

export interface TestCommandResult extends CommandResult {
    resultDateValue: Date;
    resultDateValue2: Date;
    resultValue1: string;
    userInfo: string;
}

export interface TestIfUserCanBeDeletedCommandResult extends CommandResult {
    canDelete: boolean;
    countMapItems: number;
    countMapPublicRoutes: number;
    countTrips: number;
    countUsageLogsMaps: number;
}

export interface TestRefOliObjectIdCommandResult extends CommandResult {
    refOlis: Array<RefOLI>;
}

export interface UpdateContainerCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
}

export interface UpdateDutyCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
}

export interface UpdateEventCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
}

export interface UpdateImageCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
}

export interface UpdateInfoCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
}

export interface UpdateLinkCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
}

export interface UpdateNoticeCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
}

export interface UpdatePlaceCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
}

export interface UpdateRefContainerCommandResult extends CommandResult {
    success: boolean;
}

export interface UpdateRefEventCommandResult extends CommandResult {
    success: boolean;
}

export interface UpdateRefImageCommandResult extends CommandResult {
    success: boolean;
}

export interface UpdateRefInfoCommandResult extends CommandResult {
    success: boolean;
}

export interface UpdateRefLinkCommandResult extends CommandResult {
    success: boolean;
}

export interface UpdateRefOLICommandResult extends CommandResult {
    success: boolean;
}

export interface UpdateRefPlaceCommandResult extends CommandResult {
    success: boolean;
}

export interface UpdateRefRegionCommandResult extends CommandResult {
    success: boolean;
}

export interface UpdateRefRestoCommandResult extends CommandResult {
    success: boolean;
}

export interface UpdateRefStayCommandResult extends CommandResult {
    success: boolean;
}

export interface UpdateRefTransferCommandResult extends CommandResult {
    success: boolean;
}

export interface UpdateRestoCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
}

export interface UpdateStayCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
}

export interface UpdateTransferCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
}

export interface UpdateTripCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
}

export interface UpdateTripCompleteCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
}

export interface UpdateTripShareCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
}

export interface UpdateUserCommandResult extends CommandResult {
    success: boolean;
}

export interface UpdateXDocCommandResult extends CommandResult {
    statusCode: EnumGlobalStatusCode;
}


//#endregion

//#region Data transfer objects

export interface Container {
    connectLocOnMap: boolean;
    containerId: number;
    containerItems: Array<SortObject>;
    containers: Array<Container>;
    containerType: EnumContainerType;
    duties: Array<Duty>;
    eventId: number;
    events: Array<Event>;
    id: number;
    images: Array<Image>;
    infos: Array<Info>;
    links: Array<Link>;
    notices: Array<Notice>;
    order: number;
    placeId: number;
    places: Array<Place>;
    refContainerId: number;
    refPlaceId: number;
    refRegionId: number;
    restoId: number;
    restos: Array<Resto>;
    stayId: number;
    stays: Array<Stay>;
    title: string;
    transferId: number;
    transfers: Array<Transfer>;
    tripId: number;
    userId: number;
    visibleOnMap: boolean;
    xdocs: Array<XDoc>;
}

export interface Duty {
    containerId: number;
    duration: number;
    dutyType: EnumDutyType;
    eventId: number;
    from: Date;
    id: number;
    order: number;
    placeId: number;
    priority: number;
    restoId: number;
    startAfterDuty: number;
    status: EnumDutyStatus;
    stayId: number;
    text: string;
    title: string;
    to: Date;
    transferId: number;
    tripId: number;
}

export interface Event {
    containerId: number;
    coordLat: number;
    coordLon: number;
    dateActive: number;
    description: string;
    descUrl: string;
    duties: Array<Duty>;
    eventCategory?: EnumEventCategory;
    eventType?: EnumEventType;
    fixed: boolean;
    from: Date;
    id: number;
    images: Array<Image>;
    infos: Array<Info>;
    links: Array<Link>;
    marked: boolean;
    name: string;
    notices: Array<Notice>;
    order: number;
    placeId: number;
    rating: number;
    refEventId: number;
    to: Date;
    transferId: number;
    tripId: number;
    zoom: number;
}

export interface Image {
    containerId: number;
    description: string;
    eventId: number;
    id: number;
    noticeId: number;
    order: number;
    placeId: number;
    providerName: string;
    refImageId: number;
    restoId: number;
    stayId: number;
    title: string;
    transferId: number;
    tripId: number;
    url: string;
}

export interface Info {
    containerId: number;
    dataType: EnumInfoDataType;
    eventId: number;
    id: number;
    infoType: EnumThemeType;
    order: number;
    placeId: number;
    providerName: string;
    refInfoId: number;
    restoId: number;
    stayId: number;
    text: string;
    title: string;
    transferId: number;
    tripId: number;
}

export interface Link {
    containerId: number;
    description: string;
    eventId: number;
    id: number;
    linkType: EnumThemeType;
    order: number;
    placeId: number;
    providerName: string;
    refLinkId: number;
    restoId: number;
    stayId: number;
    title: string;
    transferId: number;
    tripId: number;
    url: string;
}

export interface Notice {
    containerId: number;
    createdAt: Date;
    eventId: number;
    id: number;
    noticeType: EnumNoticeType;
    order: number;
    placeId: number;
    restoId: number;
    stayId: number;
    text: string;
    title: string;
    transferId: number;
    tripId: number;
}

export interface Place {
    containerId: number;
    containers: Array<Container>;
    coordLat: number;
    coordLon: number;
    dateActive: number;
    description: string;
    descUrl: string;
    duties: Array<Duty>;
    events: Array<Event>;
    fixed: boolean;
    from: Date;
    id: number;
    images: Array<Image>;
    infos: Array<Info>;
    links: Array<Link>;
    marked: boolean;
    name: string;
    notices: Array<Notice>;
    order: number;
    placeId: number;
    places: Array<Place>;
    rating: number;
    refPlaceId: number;
    restos: Array<Resto>;
    stays: Array<Stay>;
    to: Date;
    tripId: number;
    zoom: number;
}

export interface RefContainer {
    containerItems: Array<SortObject>;
    containerType: EnumContainerType;
    id: number;
    marked: boolean;
    order: number;
    rating: number;
    refContainerId: number;
    refContainers: Array<RefContainer>;
    refEventId: number;
    refEvents: Array<RefEvent>;
    refImages: Array<RefImage>;
    refInfos: Array<RefInfo>;
    refLinks: Array<RefLink>;
    refOlis: Array<RefOLI>;
    refPlaceId: number;
    refPlaces: Array<RefPlace>;
    refRegionId: number;
    refRestoId: number;
    refRestos: Array<RefResto>;
    refStayId: number;
    refStays: Array<RefStay>;
    refTransferId: number;
    refTransfers: Array<RefTransfer>;
    searchName: string;
    themeType?: EnumThemeType;
    title: string;
}

export interface RefEvent {
    coordLat: number;
    coordLon: number;
    dataObectjId: number;
    description: string;
    descUrl: string;
    eventCategory?: EnumEventCategory;
    eventType?: EnumEventType;
    from: Date;
    id: number;
    marked: boolean;
    name: string;
    neededHours: number;
    rating: number;
    refContainerId: number;
    refImages: Array<RefImage>;
    refInfos: Array<RefInfo>;
    refLinks: Array<RefLink>;
    refOlis: Array<RefOLI>;
    refPlaceId: number;
    refRegionId: number;
    refTransferId: number;
    sourceType?: EnumDataSourceType;
    to: Date;
    wikiName: string;
    zoom: number;
}

export interface RefImage {
    attribution: string;
    coordLat: number;
    coordLon: number;
    description: string;
    height: number;
    id: number;
    marked: boolean;
    order: number;
    providerName: string;
    quality: number;
    refContainerId: number;
    refEventId: number;
    refPlaceId: number;
    refRegionId: number;
    refRestoId: number;
    refStayId: number;
    refTransferId: number;
    sourceType?: EnumImageSourceType;
    themeType?: EnumThemeType;
    title: string;
    type?: EnumImageType;
    url: string;
    width: number;
}

export interface RefInfo {
    dataType: EnumInfoDataType;
    id: number;
    infoType: EnumThemeType;
    langCode: string;
    marked: boolean;
    order: number;
    providerName: string;
    refContainerId: number;
    refEventId: number;
    refPlaceId: number;
    refRegionId: number;
    refRestoId: number;
    refStayId: number;
    refTransferId: number;
    text: string;
    title: string;
}

export interface RefLink {
    description: string;
    id: number;
    langCode: string;
    linkType: EnumThemeType;
    marked: boolean;
    order: number;
    providerName: string;
    refContainerId: number;
    refEventId: number;
    refPlaceId: number;
    refRegionId: number;
    refRestoId: number;
    refStayId: number;
    refTransferId: number;
    title: string;
    url: string;
}

export interface RefOLI {
    id: number;
    linkType?: EnumObjectType;
    locationName: string;
    marked: boolean;
    objectId: number;
    objectName: string;
    objectText: string;
    objectType: EnumObjectType;
    refContainer: RefContainer;
    refContainerId: number;
    refEvent: RefEvent;
    refEventId: number;
    refImage: RefImage;
    refImageId: number;
    refInfo: RefInfo;
    refInfoId: number;
    refLink: RefLink;
    refLinkId: number;
    refPlace: RefPlace;
    refPlaceId: number;
    refRegionId: number;
    refResto: RefResto;
    refRestoId: number;
    refStay: RefStay;
    refStayId: number;
    refTransferId: number;
}

export interface RefPlace {
    area: number;
    coordLat: number;
    coordLon: number;
    countryCode: string;
    dataObjectId: number;
    description: string;
    descUrl: string;
    id: number;
    localName: string;
    marked: boolean;
    name: string;
    namePath: string;
    placeCode: string;
    placeType?: EnumPlaceType;
    population: number;
    rating: number;
    ratingActivity: number;
    ratingCulture: number;
    ratingNature: number;
    refContainerId: number;
    refContainers: Array<RefContainer>;
    refEvents: Array<RefEvent>;
    refImages: Array<RefImage>;
    refInfos: Array<RefInfo>;
    refLinks: Array<RefLink>;
    refOlis: Array<RefOLI>;
    refPlaceId: number;
    refPlaces: Array<RefPlace>;
    refRegionId: number;
    refRestos: Array<RefResto>;
    refStays: Array<RefStay>;
    sourceType?: EnumDataSourceType;
    wikiName: string;
    zoom: number;
}

export interface RefRegion {
    area: number;
    bestVisit: string;
    coordLat: number;
    coordLon: number;
    countryCode: string;
    dataObjectId: number;
    description: string;
    id: number;
    localName: string;
    mapType?: EnumMapType;
    name: string;
    namePath: string;
    noTryLinks: boolean;
    parentId: number;
    population: number;
    prepairLevel: number;
    refContainers: Array<RefContainer>;
    refEvents: Array<RefEvent>;
    refImages: Array<RefImage>;
    refInfos: Array<RefInfo>;
    refLinks: Array<RefLink>;
    refOlis: Array<RefOLI>;
    refPlaces: Array<RefPlace>;
    refTransfers: Array<RefTransfer>;
    refTrips: Array<Trip>;
    regionClass?: EnumRegionClass;
    regionCode: string;
    sourceName: string;
    sourceType?: EnumDataSourceType;
    subRegionType: string;
    wikiName: string;
    zoom: number;
}

export interface RefResto {
    address: string;
    coordLat: number;
    coordLon: number;
    description: string;
    descUrl: string;
    email: string;
    foodType?: EnumFoodType;
    id: number;
    marked: boolean;
    name: string;
    priceCategory: number;
    rating: number;
    refContainerId: number;
    refImages: Array<RefImage>;
    refInfos: Array<RefInfo>;
    refLinks: Array<RefLink>;
    refOlis: Array<RefOLI>;
    refPlaceId: number;
    restoType?: EnumRestoType;
    specials: string;
    tel: string;
    zoom: number;
}

export interface RefStay {
    address: string;
    bookingPlatform: number;
    bookingType: number;
    coordLat: number;
    coordLon: number;
    description: string;
    descUrl: string;
    email: string;
    id: number;
    marked: boolean;
    minimumStay: number;
    name: string;
    placeType?: EnumSituationType;
    priceCategory: number;
    rating: number;
    recommender: string;
    refContainerId: number;
    refImages: Array<RefImage>;
    refInfos: Array<RefInfo>;
    refLinks: Array<RefLink>;
    refOlis: Array<RefOLI>;
    refPlaceId: number;
    stayType?: EnumStayType;
    tel: string;
    zoom: number;
}

export interface RefTransfer {
    app: EnumApp;
    coordFromLat: number;
    coordFromLon: number;
    coordToLat: number;
    coordToLon: number;
    description: string;
    descUrl: string;
    duration: number;
    from: Date;
    fromRefPlace: RefPlace;
    fromRefPlaceId: number;
    id: number;
    marked: boolean;
    name: string;
    rating: number;
    refEvents: Array<RefEvent>;
    refInfos: Array<RefInfo>;
    refLinks: Array<RefLink>;
    routeParam: string;
    to: Date;
    toRefPlace: RefPlace;
    toRefPlaceId: number;
    transportId: number;
    transportType: EnumTransportType;
}

export interface Resto {
    address: string;
    booked: boolean;
    containerId: number;
    coordLat: number;
    coordLon: number;
    dateActive: number;
    description: string;
    descUrl: string;
    duties: Array<Duty>;
    email: string;
    fixed: boolean;
    foodType?: EnumFoodType;
    id: number;
    images: Array<Image>;
    infos: Array<Info>;
    links: Array<Link>;
    marked: boolean;
    name: string;
    notices: Array<Notice>;
    order: number;
    placeId: number;
    priceCategory: number;
    rating: number;
    refRestoId: number;
    restoType?: EnumRestoType;
    specials: string;
    tel: string;
    tripId: number;
    visitAt: Date;
    zoom: number;
}

export interface SortObject {
    id: number;
    order: number;
}

export interface Stay {
    address: string;
    booked: boolean;
    containerId: number;
    coordLat: number;
    coordLon: number;
    dateActive: number;
    description: string;
    descUrl: string;
    duties: Array<Duty>;
    email: string;
    fixed: boolean;
    from: Date;
    id: number;
    images: Array<Image>;
    infos: Array<Info>;
    links: Array<Link>;
    marked: boolean;
    name: string;
    notices: Array<Notice>;
    order: number;
    placeId: number;
    placeType?: EnumSituationType;
    priceCategory: number;
    rating: number;
    refStayId: number;
    stayType?: EnumStayType;
    tel: string;
    to: Date;
    tripId: number;
    xDocs: Array<XDoc>;
    zoom: number;
}

export interface Transfer {
    app: EnumApp;
    containerId: number;
    coordFromLat: number;
    coordFromLon: number;
    coordToLat: number;
    coordToLon: number;
    dateActive: number;
    description: string;
    descUrl: string;
    duties: Array<Duty>;
    events: Array<Event>;
    fixed: boolean;
    from: Date;
    fromPlaceName: string;
    fromRefPlaceId: number;
    id: number;
    images: Array<Image>;
    infos: Array<Info>;
    links: Array<Link>;
    marked: boolean;
    name: string;
    notices: Array<Notice>;
    order: number;
    refTransferId: number;
    routeParam: string;
    to: Date;
    toPlaceName: string;
    toRefPlaceId: number;
    transportType: EnumTransportType;
    tripId: number;
}

export interface Trip {
    bestTravelTime: string;
    category: string;
    connectLocOnMap: boolean;
    containers: Array<Container>;
    createdAt: Date;
    events: Array<Event>;
    from: Date;
    id: number;
    infos: Array<Info>;
    isRefTrip: boolean;
    links: Array<Link>;
    name: string;
    places: Array<Place>;
    refRegionId: number;
    regionName: string;
    restos: Array<Resto>;
    stays: Array<Stay>;
    to: Date;
    transfers: Array<Transfer>;
    tripPriv: number;
    tripShare: TripShare;
    userId: number;
    useTimeMode: boolean;
    viewMode: EnumTripViewMode;
    visible: boolean;
}

export interface TripShare {
    allowPrivateData: boolean;
    allowShare: boolean;
    category: string;
    changedAt: Date;
    connectLocOnMap: boolean;
    email: string;
    id: number;
    key: string;
    shareName: string;
    status: EnumShareStatus;
    tripId: number;
    tripPriv: EnumTripPriv;
    userId: number;
    useTimeMode: boolean;
    viewMode: number;
    visible: boolean;
}

export interface UsageLog {
    actionInfo: string;
    actionType: EnumActionType;
    appType: EnumAppType;
    appVer: string;
    at: Date;
    id: number;
    partnerId: string;
    referrer: string;
    sessionId: string;
    userId: number;
    userIP: string;
    userLanguage: string;
}

export interface UsageLogMaps {
    actionInfo: string;
    actionType: EnumActionType;
    appVer: string;
    at: Date;
    id: number;
    partnerId: string;
    referrer: string;
    sessionId: string;
    userId: number;
    userIP: string;
    userLanguage: string;
}

export interface User {
    accountId: string;
    adminPriv: EnumAdminPriv;
    email: string;
    firstLogin: Date;
    id: number;
    lastLogin: Date;
    loginProvider?: EnumLoginProvider;
    mapCount: number;
    mapPriv: number;
    maxDocSize: number;
    maxImageSize: number;
    maxItemsPerTrip: number;
    maxMapCount: number;
    maxTilesPerMap: number;
    maxTrips: number;
    name: string;
    roleID: number;
    tripCopyPriv: boolean;
}

export interface XDoc {
    containerId: number;
    eventId: number;
    id: number;
    order: number;
    oriFileName: string;
    placeId: number;
    providerName: string;
    restoId: number;
    stayId: number;
    title: string;
    transferId: number;
    url: string;
}


//#endregion

//#region Enums

export const enum EnumActionType {
    Undef = 0,
    init = 1,
    autostart = 2,
    search_click = 1011,
    planner_click = 1012,
    loadgpx_click = 1013,
    tracking_click = 1014,
    settings_click = 1015,
    selectmap_click = 1016,
    help_click = 1017,
    saved_click = 1018,
    search_route_click = 1019,
    search_restos_click = 1023,
    search_hotels_click = 1024,
    search_shops_click = 1025,
    search_pois_click = 1026,
    start_navi_click = 1031,
    stop_navi_click = 1032,
    activate_saved_route = 1033,
    add_route_poi = 1034,
    add_marker = 1035,
    publish_route = 1036,
    activate_public_route = 1037,
    save_route_device = 1038,
    save_route_cloud = 1039,
    send_location = 1041,
    send_livetracker = 1042,
    search_route = 1043,
    draw_route = 1044,
    draw_all_routes = 1045,
    call_ors_direction = 1051,
    call_ors_geocode = 1052,
    call_ors_reversegeocode = 1053,
    call_ors_searchautocomplete = 1054,
    public_route_by_url = 1061,
    activate_live_tracking_by_url = 1062,
    feedback_bug = 1091,
    feedback_question = 1092,
    navigate_region = 2001,
    navigate_place = 2002,
    search_region = 2003,
    search_place = 2004,
    navigate_event = 2005,
    navigate_stay = 2006,
    navigate_resto = 2007,
    navigate_container = 2008,
    load_trips = 3001,
    example_trip = 3002,
    example_stepbystep = 3003,
}

export const enum EnumAdminPriv {
    PrivNone = 0,
    PrivGet = 1,
    PrivUpdate = 2,
    PrivAdd = 3,
    PrivDelete = 4,
    PrivLevel1 = 11,
    PrivLevel2 = 12,
}

export const enum EnumApp {
    Trip4YouMaps = 1,
    GoogleMaps = 101,
}

export const enum EnumAppType {
    Undef = 0,
    Maps = 1,
    Explore = 2,
    Trips = 3,
}

export const enum EnumContainerType {
    Undef = 0,
    Standard = 1,
    Hiking = 2001,
    Cycling = 2002,
    Diving = 2003,
    Beaches = 2004,
    NationalParks = 2005,
    NatureParks = 2006,
    HikingRoute = 4001,
    BikeRoute = 4002,
    NationalPark = 4005,
}

export const enum EnumDataSourceType {
    Undef = 0,
    geonames = 1,
    wikipedia = 11,
    wikitravel = 12,
    wikivoyage = 13,
}

export const enum EnumDutyStatus {
    Undef = 0,
    wait = 1,
    active = 2,
    critical = 3,
    finished = 11,
}

export const enum EnumDutyType {
    Undef = 0,
    call = 1,
    sendmail = 2,
    waitformail = 3,
    book = 4,
    buy = 5,
    organise = 6,
    todo = 7,
    other = 99,
}

export const enum EnumEventCategory {
    Undef = 0,
    Activity = 1,
    Culture = 2,
    Nature = 3,
    Mixed = 99,
}

export const enum EnumEventType {
    Undef = 0,
    Visit = 1,
    Do = 2,
    Participate = 3,
    See = 4,
    Learn = 5,
    Hike = 11,
    Bike = 12,
    Dive = 21,
    Snorkel = 22,
    DiveSnorkel = 23,
}

export const enum EnumFoodType {
    Undef = 0,
    traditional = 1,
    international = 2,
    homemade = 3,
    vegetarian = 11,
    vegan = 12,
    seafood = 13,
    westernFood = 14,
    italian = 21,
    indian = 22,
    indonesian = 23,
    chinese = 24,
    mediteranean = 25,
    greek = 26,
    southamerican = 27,
    thai = 28,
    asian = 29,
}

export const enum EnumGlobalStatusCode {
    UnknownError = 0,
    Success = 1,
    UserNotLoggedin = 1001,
    NotEnoughPrivileges = 1002,
    ObjectNotInDb = 1003,
    AccessNotPermited = 1004,
    MissingLinkObject = 1005,
    NameConflict = 1006,
    NoTripsAvailable = 1007,
    NoItemsAvailable = 1008,
    TripIsNoDemoTrip = 1009,
    ShareKeyNotFound = 1010,
    ShareKeyAlreadyUsedByUser = 1011,
    ShareKeyAlreadyUsedByOtherUser = 1012,
    TripNotFound = 1013,
}

export const enum EnumImageSelection {
    Card = 0,
    Gallery = 1,
    Theme = 2,
    All = 9,
}

export const enum EnumImageSourceType {
    Undef = 0,
    BLOB = 1,
    Link = 2,
}

export const enum EnumImageType {
    Undef = 0,
    Banner = 1,
    Landscape = 2,
    Portrait = 3,
}

export const enum EnumInfoDataType {
    Undef = 0,
    Text = 1,
    HTML = 2,
    Data = 9,
}

export const enum EnumLoginProvider {
    GoogleLogin = 1,
    GitHubLogin = 2,
}

export const enum EnumMapType {
    Undef = 0,
    OSM = 1,
    Outdoor = 2,
    Topo = 3,
    Streets = 4,
    Satellite = 5,
    Hydrid = 6,
}

export const enum EnumNoticeType {
    Undef = 0,
    remember = 1,
    documentation = 2,
    booking = 3,
    diary = 11,
    other = 99,
}

export const enum EnumObjectType {
    Undef = 0,
    region = 1,
    container = 2,
    place = 3,
    xevent = 4,
    stay = 5,
    resto = 6,
    transfer = 7,
    info = 8,
    link = 9,
    image = 10,
    duty = 11,
    notice = 12,
    xdoc = 13,
}

export const enum EnumPlaceType {
    Undef = 0,
    city = 1,
    village = 2,
    capital = 3,
    island = 4,
    archipelago = 5,
    municipality = 6,
    borough = 7,
    beach = 11,
    lake = 12,
    mountain = 13,
    admin1capital = 31,
    admin2capital = 32,
    admin3capital = 33,
    region = 101,
}

export const enum EnumRegionClass {
    Undef = 0,
    world = 1,
    continent = 2,
    subcontinent = 3,
    region = 4,
    country = 11,
    admin1 = 12,
    admin2 = 13,
    admin3 = 14,
    island = 21,
    penuinsula = 22,
    archipelago = 23,
    container = 51,
}

export const enum EnumRestoType {
    Undef = 0,
    simplerest = 1,
    stdrest = 2,
    hotelrest = 3,
    specialrest = 8,
    stand = 9,
    fastfood = 21,
    bar = 51,
    cafe = 52,
}

export const enum EnumShareStatus {
    New = 0,
    Send = 1,
    Used = 2,
    Returned = 9,
}

export const enum EnumSituationType {
    Undef = 0,
    Center = 1,
    Suburb = 2,
    Village = 3,
    Countryside = 11,
    Riverside = 12,
    Lakeside = 13,
    Beachside = 14,
}

export const enum EnumStayType {
    Undef = 0,
    Smallhotel = 1,
    Bighotel = 2,
    Pension = 3,
    Resort = 4,
    Aparthotel = 5,
    Apartments = 6,
    Guesthouse = 11,
    HomeStay = 12,
    BAndB = 13,
    Agrotourism = 14,
}

export const enum EnumThemeType {
    Undef = 0,
    Basic = 1,
    Place = 2,
    ToSee = 3,
    ToDo = 4,
    GetIn = 5,
    GetAround = 6,
    Sleep = 7,
    Eat = 8,
    News = 9,
    Map = 10,
    Profile = 11,
    BestTravelTime = 51,
    Climate = 52,
    Politics = 53,
    Economy = 54,
    Health = 55,
    TravelInfo = 56,
    CardImage = 101,
    Booking = 201,
    BookingFlight = 202,
    BookingHotel = 203,
    BookingEvent = 204,
    SocialMedia = 211,
}

export const enum EnumTransportType {
    Undef = 0,
    Plane = 1,
    Train = 4,
    Bus = 5,
    Boat = 6,
    Taxi = 7,
    Private = 11,
    RentCar = 12,
    Bike = 21,
    Ebike = 22,
    MotorBike = 23,
    Other = 99,
}

export const enum EnumTripPriv {
    PrivNone = 0,
    PrivGet = 1,
    PrivAdd = 2,
    PrivUpdate = 3,
    PrivDelete = 4,
}

export const enum EnumTripViewMode {
    ListView = 1,
    DayByDayView = 2,
    NoticeView = 3,
    DutyView = 4,
    ImageView = 5,
}


//#endregion
